<template>
  <div class="d-inline-flex flex-column align-items-center">
    <button @click="$emit('click')" class="btn-circle btn-line mb-1">
      <i class="tmlps-icn-compare h2 m-0"></i>
    </button>
    <div class="small text-center">{{$t('lang.compare.msg')}}</div>
  </div>
</template>

<script>
export default {
  name: 'CompareButton',
};
</script>
