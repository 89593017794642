<template>
  <AppLayout >
    <div class="viewport">
      <ImageView v-if="selectedPhoto" :photo="selectedPhoto" :scale="imageZoom" @zoomin="zoomIn" @zoomout="zoomOut" />
      <div class="error-messages mt-4 alert alert-danger" v-else style="
      text-align: center;width: 50%; margin-top: 20% !important; margin-left: 25%;">

        <div class="error-messages__message">
          {{ $t('lang.sin_fotos_fecha.msg') }}
        </div>
      </div>
    </div>

    <ProjectSelectorButton :esPublico="isPublic" />

    <ImageZoom
      orientation="vertical"
      :minValue="zoomMinValue"
      :maxValue="zoomMaxValue"
      :step="1"
      v-model="imageZoom"
    />

    <CameraSelectorDrawer
      :project="project"
      :current-camera="camera.camera"
      :show="activeDrawer === 'camera-drawer'"
      @select="selectCamera"
      @close="activeDrawer = ''"
      v-if="!isPublic"
    />

    <PhotoSelectorDrawer
      :camera="camera.camera"
      :date="photo.filterDate"
      :show="activeDrawer === 'photo-drawer'"
      v-model="selectedPhoto"
      :mostrarDiaAnterior="mostrarDiaAnterior"
      close-on-select-photo
      @close="activeDrawer = ''"
      :esPublico="isPublic"
    />

    <SendPhotoModal
      :photo="selectedPhoto"
      :open="openSendPhotoModal"
      @close="openSendPhotoModal = false"
    />

    <DownloadPhotoModal
      :photo="selectedPhoto"
      :open="openDownloadPhotoModal"
      @close="openDownloadPhotoModal = false"
    />

    <template v-slot:actionbar>
  
      <div class="d-flex align-items-center justify-content-between d-lg-none">
        <div class="mx-auto">
          <b-button-group size="sm">
            <b-button variant="primary">{{$t('lang.image.msg')}}</b-button>
            <b-button
              :to="{ name: 'camera-video', params: { id: camera.camera.id, esPublico: isPublic ? 'public' : undefined } }"
              variant="outline-primary"
              >Timelapse</b-button
            >
          </b-button-group>
        </div>

        <b-button
          class="btn-circle btn-line p-0 d-flex align-items-center justify-content-center flex-col d-lg-none"
          :variant="showSubmenu ? 'primary' : 'white'"
          @click="showSubmenu = !showSubmenu"
        >
          <i class="tmlps-icn-back h3 mb-0"></i>
        </b-button>
      </div>
      <div class="d-lg-none divider mt-2"></div>

      <div class="mt-2 d-flex flex-wrap justify-content-between flex-grow-1">
        <div class="flex-grow-1 d-lg-flex justify-content-md-start">
          <TenantLogo class="d-none d-lg-block" v-if="!isPublic" />

          <CameraSelectorButton
            class="d-flex flex-column ml-lg-3"
            :active="activeDrawer === 'camera-drawer'"
            :camera="camera.camera"
            @click="toggleDrawer('camera-drawer')"
            :disabled="isPublic"
          />

          <b-button-group class="d-none ml-lg-3 d-lg-block">
            <b-button variant="primary">{{$t('lang.image.msg')}}</b-button>
            <b-button
              :to="{ name: 'camera-video', params: { id: camera.camera.id, esPublico: isPublic ? 'public' : undefined } }"
              variant="outline-primary"
              >Timelapse</b-button
            >
          </b-button-group>
        </div>

        <div class="d-flex justify-content-center">
            <b-button @mouseover="hover.img1 = true" @mouseleave="hover.img1 = false" class="btn-transparent btn-circle btn-line mb-1" @click="photoUltima"  variant="outline-primary"> <img :class="hover.img1 ? `colorBlanco` : ''" src="@/assets/iconos/1.svg" alt=""></b-button>
            <b-button style="margin-left: 0.5rem !important;" @mouseover="hover.img2 = true" @mouseleave="hover.img2 = false" class="btn-transparent btn-circle btn-line mb-1" @click="photoSiguiente"  variant="outline-primary"><img :class="hover.img2 ? `colorBlanco` : ''" src="@/assets/iconos/2.svg" alt=""></b-button>
          <PhotoDateSelectorButton
            style="margin-left: 0.5rem !important;"
            :active="activeDrawer === 'photo-drawer'"
            :photo="selectedPhoto"
            @click="toggleDrawer('photo-drawer')"
          />
            <b-button style="margin-left: 0.5rem !important;" @mouseover="hover.img3 = true" @mouseleave="hover.img3 = false" class="btn-transparent btn-circle btn-line mb-1" @click="photoAnterior"  variant="outline-primary"><img :class="hover.img3 ? `colorBlanco` : ''" src="@/assets/iconos/3.svg" alt=""></b-button>
            <b-button style="margin-left: 0.5rem !important;" @mouseover="hover.img4 = true" @mouseleave="hover.img4 = false" class="btn-transparent btn-circle btn-line mb-1" @click="photoPrimera"  variant="outline-primary"><img :class="hover.img4 ? `colorBlanco` : ''" src="@/assets/iconos/4.svg" alt=""></b-button>

        </div>

        <div
          class="pt-2 mt-2 d-lg-flex flex-grow-1 pt-lg-0 mt-lg-0 navbar-buttons"
          v-show="showSubmenu"
        >
          <div class="d-flex justify-content-around justify-content-lg-start">
            <CompareButton class="ml-lg-3" @click="$router.push({ name: 'camera-compare', params: {esPublico: isPublic ? 'public' : undefined} })" />
            <DownloadButton v-if="!isPublic" class="ml-lg-3" @click="openDownloadPhotoModal = true" />
            <SendButton v-if="!isPublic" class="ml-lg-3 d-lg-none" @click="openSendPhotoModal = true" />
            <MenuButton v-if="!isPublic" class="ml-lg-3 d-lg-none" @click="handleMenuButtonClick" />
          </div>

          <div class="d-none d-lg-flex flex-grow-1 justify-content-end">
            <SendButton v-if="!isPublic" class="ml-lg-3" @click="openSendPhotoModal = true" />
            <MenuButton v-if="!isPublic" class="ml-lg-3" @click="handleMenuButtonClick" />
          </div>
        </div>
      </div>
    </template>
  </AppLayout>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import CameraSelectorButton from '@/components/CameraSelectorButton.vue';
import CameraSelectorDrawer from '@/components/CameraSelectorDrawer.vue';
import CompareButton from '@/components/CompareButton.vue';
import DownloadButton from '@/components/DownloadButton.vue';
import DownloadPhotoModal from '@/components/DownloadPhotoModal.vue';
import ImageView from '@/components/ImageView.vue';
import ImageZoom from '@/components/ImageZoom.vue';
import MenuButton from '@/components/MenuButton.vue';
import PhotoDateSelectorButton from '@/components/PhotoDateSelectorButton.vue';
import PhotoSelectorDrawer from '@/components/PhotoSelectorDrawer.vue';
import ProjectSelectorButton from '@/components/ProjectSelectorButton.vue';
import SendButton from '@/components/SendButton.vue';
import SendPhotoModal from '@/components/SendPhotoModal.vue';
import TenantLogo from '@/components/TenantLogo.vue';

export default {
  name: 'CameraTimelapseView',
  components: {
    CompareButton,
    CameraSelectorButton,
    CameraSelectorDrawer,
    DownloadButton,
    DownloadPhotoModal,
    ImageView,
    ImageZoom,
    MenuButton,
    PhotoDateSelectorButton,
    PhotoSelectorDrawer,
    ProjectSelectorButton,
    SendButton,
    SendPhotoModal,
    TenantLogo,
  },
  data() {
    return {
      isPublic: false,
      viewMode: 'default',
      viewOptions: [
        { text: '', value: 'default' },
        { text: 'Timelapse', value: 'timelapse' },
      ],
      showSubmenu: false,
      activeDrawer: '',
      openSendPhotoModal: false,
      openDownloadPhotoModal: false,
      imageZoom: 1,
      zoomMinValue: 1,
      zoomMaxValue: 10,
      selectedPhoto: null,
      posPhoto:0,
      dPrimera:true,
      dAnterior:true,
      dSiguiente:false,
      dUltima:false,

      mostrarDiaAnterior: false,
      listaFotosDia: [],
      hover:{
        img1:false,
        img2:false,
        img3:false,
        img4:false,
      }
    };
  },
  watch: {
    'photo.photos': function(){
      this.listaFotosDia = this.photo.photos;
      if(this.mostrarDiaAnterior == 'diaSiguiente'){
        this.posPhoto = this.listaFotosDia.length-1;
      }else{
        this.posPhoto = 0;
      }
      
    },
    selectedPhoto: function(){
      if(this.selectedPhoto){
        this.posPhoto = this.listaFotosDia.findIndex(x => x.id === this.selectedPhoto.id);
      }
    }
  },
  computed: {
    ...mapState(['user', 'camera', 'photo']),
    ...mapState('project', ['project']),
    ...mapGetters('project', ['projectLogoUrl']),
  },
  methods:{

  },
  created(){
    this.viewOptions[0].text = this.$t('lang.image.msg');
    
    this.isPublic = this.$route.params.esPublico == 'public';
    console.log("🚀 created ~ this.isPublic:", this.isPublic)
  },
  methods: {
    selectCamera(camera) {
      if (this.camera.camera.id !== camera.id) {
        this.selectedPhoto = null;
        this.$router.push({ name: 'camera-home', params: { id: camera.id } });
      }
    },
    handleMenuButtonClick() {
      this.$router.push({ name: 'profile-home', params: { project: this.project.id } });
    },
    toggleDrawer(drawer) {
      if (this.activeDrawer === drawer) {
        this.activeDrawer = '';
      } else {
        this.activeDrawer = drawer;
      }
    },
    zoomIn(event) {
      this.imageZoom = Math.min(Math.ceil(this.imageZoom + event.scaleAmount), this.zoomMaxValue);
    },
    zoomOut(event) {
      this.imageZoom = Math.max(Math.floor(this.imageZoom - event.scaleAmount), this.zoomMinValue);
    },
    scrollToElement(tipo) {
      var container = this.$el.querySelector("#divAbajo");
      if(tipo == 'bottom'){
        container.scrollTop = container.scrollHeight;
      }else{
        container.scrollTop = 0;
      }
    },
    photoPrimera(){
      this.mostrarDiaAnterior=false;
      const listPhotos = this.listaFotosDia;
      
      if(this.posPhoto == listPhotos.length-1){
        this.dSiguiente = false;
        this.dUltima = false;
      }
      
      this.posPhoto = 0;
      this.selectedPhoto = listPhotos[this.posPhoto];
      this.dPrimera = true;
      this.dAnterior = true;      
      this.scrollToElement('top');
    },
    photoAnterior(){
      console.log('siguiente');
      //console.log("🚀 5557 this.posPhoto", this.posPhoto)
      //console.log("🚀 6667 this.listaFotosDia", this.listaFotosDia)
      const listPhotos = this.listaFotosDia;
      //console.log("length111 photoAnterior ~ listPhotos", listPhotos.length)
      
      if(this.posPhoto == listPhotos.length-1){
        this.dSiguiente = false;
        this.dUltima = false;
      }
      
      this.posPhoto = this.posPhoto-1;
      this.selectedPhoto = listPhotos[this.posPhoto];
      //console.log("🚀 photo.filterDate", this.photo.filterDate)
      if(this.posPhoto === 0){
        
        //this.photo.filterDate = 'Tue Dec 18 2021 17:07:50 GMT-0500 (hora estándar oriental)'
        this.dPrimera = true;
        this.dAnterior = true
      }

      if(this.posPhoto === -1){
        console.log('Debe pasar al dia anterior?');
        this.mostrarDiaAnterior='diaSiguiente';
        //this.posPhoto = 0; //se selecciona la ultima foto
        //console.log("length222 ~ this.listaFotosDia", this.listaFotosDia.length)
        //this.posPhoto = listPhotos.length-1;
        //this.selectedPhoto = listPhotos[this.posPhoto];
      }else{
        this.mostrarDiaAnterior=false;
      }

      
    },
    photoSiguiente(){
      console.log('anterior');
      if(this.posPhoto === 0){
        this.dPrimera = false;
        this.dAnterior = false
      }
      const listPhotos = this.listaFotosDia;
      //console.log("length33 photoAnterior ~ listPhotos", listPhotos.length)
      this.posPhoto = this.posPhoto+1;
      this.selectedPhoto = listPhotos[this.posPhoto];
      

      if(this.posPhoto == listPhotos.length-1){
        //console.log("length44 photoAnterior ~ listPhotos", this.listaFotosDia.length)
        this.dSiguiente = true;
        this.dUltima = true;
      }else if(this.posPhoto == listPhotos.length){
        console.log('Debe pasar al dia siguiente?');
        this.mostrarDiaAnterior='diaAnterior';
      }else{
        this.mostrarDiaAnterior=false;
      }
    },
    photoUltima(){
      this.mostrarDiaAnterior=false;
      if(this.posPhoto === 0){
        this.dPrimera = false;
        this.dAnterior = false
      }
      const listPhotos = this.listaFotosDia;
      this.posPhoto = listPhotos.length-1;
      this.selectedPhoto = listPhotos[this.posPhoto];
      this.dSiguiente = true;
      this.dUltima = true;
      this.scrollToElement('bottom');
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';

.viewport {
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
  background: #242424;
}

.divider {
  height: 2px;
  background: #c1c1c1;
}

.navbar-buttons {
  width: 100%;

  @include media-breakpoint-up(lg) {
    width: auto;
  }
}

.colorBlanco{
  filter:invert(98%) sepia(0%) saturate(0%) hue-rotate(208deg) brightness(103%) contrast(104%);
}
</style>
