import { render, staticRenderFns } from "./CompareButton.vue?vue&type=template&id=6a497e12&"
import script from "./CompareButton.vue?vue&type=script&lang=js&"
export * from "./CompareButton.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/marcosalazar/Documents/HoneyMustard/Timelapse/timelapse-web/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6a497e12')) {
      api.createRecord('6a497e12', component.options)
    } else {
      api.reload('6a497e12', component.options)
    }
    module.hot.accept("./CompareButton.vue?vue&type=template&id=6a497e12&", function () {
      api.rerender('6a497e12', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/CompareButton.vue"
export default component.exports