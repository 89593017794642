<template>
  <Modal :open="open" @close="$emit('close')" title="Descargar imagen">
    <p>¿Qué deseas descargar?</p>

    <div class="mt-4">
      <input type="radio" id="current" name="download-option" value="current" v-model="option" />
      <label for="current" class="ml-3">Imagen seleccionada</label>
    </div>
    <div class="mt-3">
      <input type="radio" id="date" name="download-option" value="date" v-model="option" />
      <label for="date" class="ml-3">Rango de imágenes</label>
    </div>

    <div class="mt-3" v-if="option === 'date'">
      <VueCtkDateTimePicker
        inline
        no-keyboard
        only-date
        no-header
        format="YYYY-MM-DD hh:mm"
        :disabled-dates="camera.camera.dates_without_photos"
        :max-date="maxDate"
        :min-date="minDate"
        no-button
        color="var(--primary)"
        range
        no-shortcuts
        locale="es"
        v-model="dateRange"
      />
    </div>

    <div v-if="error" class="text-danger pt-2 text-small">{{ error }}</div>

    <div class="mt-4 d-flex justify-content-center align-items-center">
      <button type="button" class="button button--secondary w-100 mr-1" @click="$emit('close')">
        Cancelar
      </button>
      <button
        type="button"
        class="button w-100 ml-1"
        :disabled="!canDownload || loading"
        @click="handleDownloadPhotos"
        :class="{ 'button--disabled': !canDownload || loading }"
      >
      <b-spinner v-if="loading" small></b-spinner>
        {{$t('lang.download.msg')}}
      </button>
    </div>
  </Modal>
</template>

<script>
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
import dateUtil from '@/util/date';
import Modal from '@/components/Modal';
import { mapState } from 'vuex';

export default {
  name: 'DownloadPhotoModal',
  components: { Modal, VueCtkDateTimePicker },
  props: {
    open: { type: Boolean, default: false },
    photo: { type: Object },
  },
  data() {
    return {
      option: 'current',
      dateRange: null,
      //maxDate: new Date().toISOString(),
      loading: false,
      error: '',
    };
  },
  computed: {
    ...mapState(['camera']),
    canDownload() {
      if (this.option === 'current' && this.photo) {
        return true;
      }

      if (this.dateRange && this.dateRange.start && this.dateRange.end) {
        return true;
      }
      return false;
    },
    minDate() {
      return this.camera.camera.first_capture_at
        ? dateUtil.toDateString(new Date(this.camera.camera.first_capture_at))
        : dateUtil.toDateString(new Date());
    },
    maxDate() {
      return this.camera.camera.last_capture_at
        ? dateUtil.toDateString(new Date(this.camera.camera.last_capture_at))
        : dateUtil.toDateString(new Date());
    },
  },
  methods: {
    handleDownloadPhotos() {
      this.error = '';

      if (this.option === 'current') {
        return this.downloadCurrentPhoto();
      }

      return this.downloadPhotosRange();
    },
    async downloadCurrentPhoto() {
      const blob = await fetch(this.photo.url, { cache: 'no-cache' }).then((response) =>
        response.blob(),
      );
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = this.photo.filename;
      link.click();
    },
    resetForm() {
      this.option = 'current';
      this.dateRange = null;
      this.error = '';
    },
    async downloadPhotosRange() {
      this.loading = true;
      // console.log('fecha- 0', this.dateRange.start);
      // console.log('fecha- 1', new Date());
      // console.log('fecha- 2',new Date(this.dateRange.start.replace(/-/g, "/")));
      try {
        await this.$store.dispatch('photo/download', {
          camera: this.camera.camera,
          startDate: dateUtil.startOfDay(new Date(this.dateRange.start.replace(/-/g, "/"))),
          endDate: dateUtil.endOfDay(new Date(this.dateRange.end.replace(/-/g, "/"))),
        });
        this.$store.dispatch(
          'notification/addSuccess',
          'Estamos preparando tu descarga. En breve recibirás un correo electrónico.',
        );
        this.loading = false;
        this.$emit('close');
        this.resetForm();
      } catch (error) {
        console.log(error);
        const response = error?.response;

        if (response.status === 500) {
          return (this.error = 'Ocurrió un error al descargar las fotografias.');
        }

        if (response.data) {
          const jsonError = JSON.parse(await error.response.data.text());

          if (jsonError.error) {
            this.error = jsonError.error.message;
          }

          if (jsonError.errors) {
            const firstError = Object.keys(jsonError.errors)[0];
            this.error = jsonError.errors[firstError][0];
          }
        }
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="sass" scoped>
label
  margin: 0
  font-size: 0.875rem

.button
  background-color: var(--primary)
  padding: 0.75rem 3.5rem
  border-radius: 0.25rem
  color: white
  font-size: 0.875rem

  &--secondary
    background-color: rgba(74,98,212,0.2)
    padding: 0.75rem 3.5rem
    border-radius: 0.25rem
    color: #242424
    font-size: 0.875rem

  &--disabled
    background-color: #E5E7EB
    color: gray
    cursor: not-allowed
</style>
