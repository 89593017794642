var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "image-zoom",
      class: { "image-zoom--vertical": _vm.orientation === "vertical" }
    },
    [
      _c(
        "button",
        { staticClass: "image-zoom__zoom-out", on: { click: _vm.zoomOut } },
        [
          _c(
            "svg",
            {
              attrs: {
                height: "24",
                width: "24",
                fill: "none",
                stroke: "currentColor",
                viewBox: "0 0 24 24"
              }
            },
            [
              _c("path", {
                attrs: {
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round",
                  "stroke-width": "2",
                  d: "M20 12H4"
                }
              })
            ]
          )
        ]
      ),
      _c("input", {
        staticClass: "image-zoom__slider",
        attrs: {
          type: "range",
          min: _vm.minValue,
          max: _vm.maxValue,
          step: _vm.step
        },
        domProps: { value: _vm.value },
        on: { input: _vm.handleZoomInput }
      }),
      _c(
        "button",
        { staticClass: "image-zoom__zoom-in", on: { click: _vm.zoomIn } },
        [
          _c(
            "svg",
            {
              attrs: {
                height: "24",
                width: "24",
                fill: "none",
                stroke: "currentColor",
                viewBox: "0 0 24 24"
              }
            },
            [
              _c("path", {
                attrs: {
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round",
                  "stroke-width": "2",
                  d: "M12 6v6m0 0v6m0-6h6m-6 0H6"
                }
              })
            ]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }