<template>
  <div class="image-zoom" :class="{ 'image-zoom--vertical': orientation === 'vertical' }">
    <button class="image-zoom__zoom-out" @click="zoomOut">
      <svg height="24" width="24" fill="none" stroke="currentColor" viewBox="0 0 24 24">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20 12H4"></path>
      </svg>
    </button>

    <input
      class="image-zoom__slider"
      type="range"
      :min="minValue"
      :max="maxValue"
      :step="step"
      @input="handleZoomInput"
      :value="value"
    />

    <button class="image-zoom__zoom-in" @click="zoomIn">
      <svg height="24" width="24" fill="none" stroke="currentColor" viewBox="0 0 24 24">
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M12 6v6m0 0v6m0-6h6m-6 0H6"
        ></path>
      </svg>
    </button>
  </div>
</template>

<script>
export default {
  name: 'ImageZoom',
  props: {
    orientation: { type: String, default: 'horizontal' },
    value: { type: Number },
    minValue: { type: Number, default: 1 },
    maxValue: { type: Number, default: 10 },
    step: { type: Number, default: 1 },
  },
  data() {
    return {
      zoom: this.value,
    };
  },
  methods: {
    zoomIn() {
      this.zoom = Math.min(this.value + this.step, this.maxValue);
      this.$emit('input', this.zoom);
    },
    zoomOut() {
      this.zoom = Math.max(this.value - this.step, this.minValue);
      this.$emit('input', this.zoom);
    },
    handleZoomInput(event) {
      this.zoom = parseInt(event.target.value, 10);
      this.$emit('input', this.zoom);
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';

.image-zoom {
  $self: &;
  position: absolute;
  right: 2rem;
  bottom: 2rem;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 9999px;

  &--vertical {
    flex-direction: column-reverse;
    padding: 0.3125rem 0;
    width: 2.1875rem;

    #{ $self }__slider {
      width: 95px;
      transform: rotate(-90deg);
      margin: 2.5rem 0;
    }
    #{ $self }__zoom-in {
      margin-bottom: 0.5rem;

      @include media-breakpoint-up(md) {
        margin-bottom: 1rem;
      }
    }
    #{ $self }__zoom-out {
      margin-top: 0.25rem;

      @include media-breakpoint-up(md) {
        margin-top: 0.75rem;
      }
    }
  }

  &__slider {
    appearance: none;
    flex-grow: 1;

    &:focus {
      outline: none;
    }
    &::slider-runnable-track,
    &::range-track {
      background-color: var(--primary);
      height: 2px;
      border-radius: 5px;
      cursor: pointer;
    }
    &::-webkit-slider-runnable-track,
    &::-webkit-range-track {
      background-color: var(--primary);
      height: 2px;
      cursor: pointer;
      -webkit-appearance: none;
    }
    &::range-thumb {
      height: 15px;
      width: 15px;
      background-color: var(--primary);
      border: none;
      cursor: pointer;
    }
    &::-webkit-slider-thumb {
      height: 15px;
      width: 15px;
      margin-top: -6px;
      background-color: var(--primary);
      border: none;
      border-radius: 9999px;
      cursor: pointer;
      -webkit-appearance: none;
    }
  }
}
</style>
