<template>
  <AppLayout>
    <div class="viewport">
      <div class="embed-camera" v-html="camera.camera.embed_script"></div>

      <ProjectSelectorButton v-if="!esCamaraIframe" />

      <CameraSelectorDrawer
        :project="project"
        :current-camera="camera.camera"
        :show="activeDrawer === 'camera-drawer'"
        @select="selectCamera"
        @close="activeDrawer = ''"
      />
    </div>

    <template v-slot:actionbar>
      <div class="d-flex flex-wrap justify-content-between flex-grow-1">
        <div class="flex-grow-1 d-md-flex justify-content-md-start">

        <div style="width: 100%; display: grid; grid-template-columns: auto auto auto auto auto;">
          
 <!--1--><div style="display: flex;align-items: center; justify-content: center;">
        <TenantLogo class="d-none d-md-block" />
         </div>

 <!--2--><div style="display: flex;align-items: center; justify-content: center;">
            

            <div  class="d-none d-lg-inline-flex flex-column align-items-center align-self-end mr-3" :class="{ 'align-self-end--iframe': esCamaraIframe }">
              <b-button
              class="btn-circle mb-1 px-0"
              variant="primary"
              @click="$router.push({ name: 'project-list' })"
            >
              <i class="tmlps-icn-back h3 m-0"></i>
            </b-button>
            <div class="small--iframe text-center">{{$t('lang.return.msg')}}</div>
           </div>
            
          </div>

  <!--3--><div style="display: flex;align-items: center; justify-content: center;">
            <div v-if="esCamaraIframe" style="text-align: center;">
              <h5>{{projectName}}</h5>
            </div>
            <div v-if="!esCamaraIframe" style="margin:10px auto">
              <h6>{{$t('lang.texto_camara.msg')}}</h6>
             </div>
          </div>
        
  <!--4--><div style="display: flex;align-items: center; justify-content: center;">
            <CameraSelectorButton
            class="d-flex flex-column ml-md-3"
            :active="activeDrawer === 'camera-drawer'"
            :camera="camera.camera"
            @click="toggleDrawer('camera-drawer')"
          />
             
          </div>
          
  <!--5--><div style="display: flex;align-items: center; justify-content: center;">
            <div style="position: relative;">
              <ProjectSelectorButton  />
            </div>
          </div>
        </div>  
  

  
  
        </div>
      </div>
    </template>
  </AppLayout>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import CameraSelectorButton from '@/components/CameraSelectorButton.vue';
import CameraSelectorDrawer from '@/components/CameraSelectorDrawer.vue';
import ProjectSelectorButton from '@/components/ProjectSelectorButton.vue';
import TenantLogo from '@/components/TenantLogo.vue';

export default {
  name: 'CameraTimelapseView',
  components: {
    CameraSelectorButton,
    CameraSelectorDrawer,
    ProjectSelectorButton,
    TenantLogo,
  },
  data() {
    return {
      activeDrawer: '',
    };
  },
  computed: {
    ...mapState('camera', ['esCamaraIframe']),
    ...mapState(['camera']),
    ...mapState('project', ['project']),
    ...mapGetters('project', ['projectLogoUrl', 'projectName']),
  },
  methods: {
    selectCamera(camera) {
      if (this.camera.camera.id !== camera.id) {
        this.$router.push({ name: 'camera-home', params: { id: camera.id } });
      }
    },
    handleMenuButtonClick() {
      this.$router.push({ name: 'profile-home', params: { project: this.project.id } });
    },
    toggleDrawer(drawer) {
      if (this.activeDrawer === drawer) {
        this.activeDrawer = '';
      } else {
        this.activeDrawer = drawer;
      }
    },
  },
};
</script>

<style lang="sass">

.toggle-button--iframe
  border: none !important
  background-color: var(--primary) !important
  color: white !important

.small--iframe
  display: none

.project-selector--iframe
  position: relative !important
  top: 0 !important
  left: 0 !important

.project-selector__label--iframe
  display: none !important

.project-selector__logo--iframe
  width: 60px !important
  height: 60px !important

.actionbar--iframe
  padding-top: 0.5rem !important
  padding-bottom: 0.5rem !important
  //background: #242424 !important
  //box-shadow: none !important

.justify-content-end--iframe
  float: right !important

.align-self-end--iframe 
    align-self: auto !important

.viewport
  height: 100%
  width: 100%
  position: relative
  overflow: hidden
  background: #242424

.embed-camera
  height: 100%
  width: 100%
  position: relative

  > iframe
    position: absolute !important
    top: 0
    right: 0
    bottom: 0
    left: 0
    width: 100% !important
    height: 100% !important
  > div
    height: calc(100% + 56px)
    //height: 100%
    margin-top:-30px
    > iframe
      background:#242424 !important
      //height: 100%
</style>
