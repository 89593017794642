var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "AppLayout",
    {
      scopedSlots: _vm._u([
        {
          key: "actionbar",
          fn: function() {
            return [
              _c(
                "div",
                {
                  staticClass:
                    "d-flex flex-wrap justify-content-between flex-grow-1"
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "flex-grow-1 d-md-flex justify-content-md-start"
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            width: "100%",
                            display: "grid",
                            "grid-template-columns": "auto auto auto auto auto"
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "align-items": "center",
                                "justify-content": "center"
                              }
                            },
                            [
                              _c("TenantLogo", {
                                staticClass: "d-none d-md-block"
                              })
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "align-items": "center",
                                "justify-content": "center"
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-none d-lg-inline-flex flex-column align-items-center align-self-end mr-3",
                                  class: {
                                    "align-self-end--iframe": _vm.esCamaraIframe
                                  }
                                },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "btn-circle mb-1 px-0",
                                      attrs: { variant: "primary" },
                                      on: {
                                        click: function($event) {
                                          return _vm.$router.push({
                                            name: "project-list"
                                          })
                                        }
                                      }
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "tmlps-icn-back h3 m-0"
                                      })
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "small--iframe text-center"
                                    },
                                    [_vm._v(_vm._s(_vm.$t("lang.return.msg")))]
                                  )
                                ],
                                1
                              )
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "align-items": "center",
                                "justify-content": "center"
                              }
                            },
                            [
                              _vm.esCamaraIframe
                                ? _c(
                                    "div",
                                    { staticStyle: { "text-align": "center" } },
                                    [
                                      _c("h5", [
                                        _vm._v(_vm._s(_vm.projectName))
                                      ])
                                    ]
                                  )
                                : _vm._e(),
                              !_vm.esCamaraIframe
                                ? _c(
                                    "div",
                                    { staticStyle: { margin: "10px auto" } },
                                    [
                                      _c("h6", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("lang.texto_camara.msg")
                                          )
                                        )
                                      ])
                                    ]
                                  )
                                : _vm._e()
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "align-items": "center",
                                "justify-content": "center"
                              }
                            },
                            [
                              _c("CameraSelectorButton", {
                                staticClass: "d-flex flex-column ml-md-3",
                                attrs: {
                                  active: _vm.activeDrawer === "camera-drawer",
                                  camera: _vm.camera.camera
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.toggleDrawer("camera-drawer")
                                  }
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "align-items": "center",
                                "justify-content": "center"
                              }
                            },
                            [
                              _c(
                                "div",
                                { staticStyle: { position: "relative" } },
                                [_c("ProjectSelectorButton")],
                                1
                              )
                            ]
                          )
                        ]
                      )
                    ]
                  )
                ]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "div",
        { staticClass: "viewport" },
        [
          _c("div", {
            staticClass: "embed-camera",
            domProps: { innerHTML: _vm._s(_vm.camera.camera.embed_script) }
          }),
          !_vm.esCamaraIframe ? _c("ProjectSelectorButton") : _vm._e(),
          _c("CameraSelectorDrawer", {
            attrs: {
              project: _vm.project,
              "current-camera": _vm.camera.camera,
              show: _vm.activeDrawer === "camera-drawer"
            },
            on: {
              select: _vm.selectCamera,
              close: function($event) {
                _vm.activeDrawer = ""
              }
            }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }