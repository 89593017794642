var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "imageContainerRef",
      staticClass: "image-view",
      class: { "image-view--state-moving": _vm.isMoving }
    },
    [
      _c("img", {
        ref: "imageRef",
        staticClass: "image-view__image",
        class: { "image-view__image--loaded": _vm.isLoaded },
        style:
          "transform: scale(" +
          this.scale +
          "); left: " +
          _vm.x +
          "px; top: " +
          _vm.y +
          "px;",
        attrs: {
          ondragstart: "return false;",
          src: _vm.photoUrl,
          alt: _vm.photoDescription
        }
      }),
      !_vm.isLoaded && _vm.photo
        ? _c("div", { staticClass: "image-view__loader" }, [
            _vm._v("Cargando imágen...")
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }