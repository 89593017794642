var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Modal",
    {
      attrs: { open: _vm.open, title: "Descargar imagen" },
      on: {
        close: function($event) {
          return _vm.$emit("close")
        }
      }
    },
    [
      _c("p", [_vm._v("¿Qué deseas descargar?")]),
      _c("div", { staticClass: "mt-4" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.option,
              expression: "option"
            }
          ],
          attrs: {
            type: "radio",
            id: "current",
            name: "download-option",
            value: "current"
          },
          domProps: { checked: _vm._q(_vm.option, "current") },
          on: {
            change: function($event) {
              _vm.option = "current"
            }
          }
        }),
        _c("label", { staticClass: "ml-3", attrs: { for: "current" } }, [
          _vm._v("Imagen seleccionada")
        ])
      ]),
      _c("div", { staticClass: "mt-3" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.option,
              expression: "option"
            }
          ],
          attrs: {
            type: "radio",
            id: "date",
            name: "download-option",
            value: "date"
          },
          domProps: { checked: _vm._q(_vm.option, "date") },
          on: {
            change: function($event) {
              _vm.option = "date"
            }
          }
        }),
        _c("label", { staticClass: "ml-3", attrs: { for: "date" } }, [
          _vm._v("Rango de imágenes")
        ])
      ]),
      _vm.option === "date"
        ? _c(
            "div",
            { staticClass: "mt-3" },
            [
              _c("VueCtkDateTimePicker", {
                attrs: {
                  inline: "",
                  "no-keyboard": "",
                  "only-date": "",
                  "no-header": "",
                  format: "YYYY-MM-DD hh:mm",
                  "disabled-dates": _vm.camera.camera.dates_without_photos,
                  "max-date": _vm.maxDate,
                  "min-date": _vm.minDate,
                  "no-button": "",
                  color: "var(--primary)",
                  range: "",
                  "no-shortcuts": "",
                  locale: "es"
                },
                model: {
                  value: _vm.dateRange,
                  callback: function($$v) {
                    _vm.dateRange = $$v
                  },
                  expression: "dateRange"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.error
        ? _c("div", { staticClass: "text-danger pt-2 text-small" }, [
            _vm._v(_vm._s(_vm.error))
          ])
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "mt-4 d-flex justify-content-center align-items-center"
        },
        [
          _c(
            "button",
            {
              staticClass: "button button--secondary w-100 mr-1",
              attrs: { type: "button" },
              on: {
                click: function($event) {
                  return _vm.$emit("close")
                }
              }
            },
            [_vm._v(" Cancelar ")]
          ),
          _c(
            "button",
            {
              staticClass: "button w-100 ml-1",
              class: { "button--disabled": !_vm.canDownload || _vm.loading },
              attrs: {
                type: "button",
                disabled: !_vm.canDownload || _vm.loading
              },
              on: { click: _vm.handleDownloadPhotos }
            },
            [
              _vm.loading
                ? _c("b-spinner", { attrs: { small: "" } })
                : _vm._e(),
              _vm._v(" " + _vm._s(_vm.$t("lang.download.msg")) + " ")
            ],
            1
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }