<template>
  <div class="image-view" :class="{ 'image-view--state-moving': isMoving }" ref="imageContainerRef">
    <img
      ref="imageRef"
      ondragstart="return false;"
      :src="photoUrl"
      :alt="photoDescription"
      class="image-view__image"
      :class="{ 'image-view__image--loaded': isLoaded }"
      :style="`transform: scale(${this.scale}); left: ${x}px; top: ${y}px;`"
    />
    <div v-if="!isLoaded && photo" class="image-view__loader">Cargando imágen...</div>
  </div>
</template>

<script>
import Hammer from 'hammerjs';

export default {
  name: 'ImageView',
  props: {
    photo: { type: Object },
    scale: { type: Number, default: 1 },
  },
  data() {
    return {
      isMoving: false,
      isLoaded: false,
      currentX: null,
      currentY: null,
      x: 0,
      y: 0,
      imageLoadListener: null,
      wheelListener: null,
      mouseDownListener: null,
      mouseUpListener: null,
      transitionEndListener: null,
    };
  },
  watch: {
    photo: function() {
      this.isLoaded = false;
      this.resetPosition();
    },
  },
  computed: {
    photoUrl() {
      return this.photo?.url ?? '';
    },
    photoDescription() {
      return this.photo?.name ?? '';
    },
  },
  methods: {
    resetPosition() {
      this.currentX = 0;
      this.currentY = 0;
      this.x = 0;
      this.y = 0;
    },

    adjustImage() {
      const imageBounds = this.$refs.imageRef.getBoundingClientRect();
      const containerBounds = this.$refs.imageContainerRef.getBoundingClientRect();
      const originalImageWidth = this.$refs.imageRef.clientWidth;
      const originalContainerWidth = this.$refs.imageContainerRef.clientWidth;
      const containerWidthOverhang = (originalContainerWidth - originalImageWidth) / 2;
      const imageWidthOverhang = (imageBounds.width - originalImageWidth) / 2;
      let finalWidthOverhang = imageWidthOverhang;
      let finalHeightOverhang = 0;

      if (this.scale > 1) {
        finalWidthOverhang = imageWidthOverhang - containerWidthOverhang;
        finalHeightOverhang = (containerBounds.height - imageBounds.height) / 2;
      }

      if (imageBounds.left > containerBounds.left) {
        this.x = finalWidthOverhang;
        this.currentX = finalWidthOverhang;
      } else if (imageBounds.right < containerBounds.right) {
        this.x = -finalWidthOverhang;
        this.currentX = -finalWidthOverhang;
      }

      if (imageBounds.top > containerBounds.top) {
        this.y = -finalHeightOverhang;
        this.currentY = -finalHeightOverhang;
      } else if (imageBounds.bottom < containerBounds.bottom) {
        this.y = finalHeightOverhang;
        this.currentY = finalHeightOverhang;
      }
    },
  },

  mounted() {
    const vm = this;

    this.imageLoadListener = this.$refs.imageRef.addEventListener('load', function() {
      vm.isLoaded = true;
    });

    this.wheelListener = this.$refs.imageRef.addEventListener('wheel', function(event) {
      event.preventDefault();

      if (event.deltaY > 0) {
        vm.$emit('zoomout', { scaleAmount: 0.2 });
      } else {
        vm.$emit('zoomin', { scaleAmount: 0.2 });
      }
    });

    this.transitionEndListener = this.$refs.imageRef.addEventListener('transitionend', function() {
      vm.adjustImage();
    });

    this.mouseDownListener = this.$refs.imageRef.addEventListener('mousedown', function() {
      this.style.cursor = 'grabbing';
    });
    this.mouseUpListener = this.$refs.imageRef.addEventListener('mouseup', function() {
      this.style.cursor = 'grab';
    });

    // HammerJS
    const hammertime = new Hammer(this.$refs.imageRef, {});
    hammertime.get('pan').set({ direction: Hammer.DIRECTION_ALL });
    hammertime.get('pinch').set({ enable: true });
    const style = window.getComputedStyle(this.$refs.imageRef, null);

    this.currentX = parseInt(style.left, 10);
    this.currentY = parseInt(style.top, 10);

    // Handle touch gestures
    hammertime.on('pinch', function(evt) {
      console.log(`zooming: ${evt.scale}`);
      vm.$emit('zoomin', { scaleAmount: 1 });
      vm.adjustImage();
    });

    hammertime.on('doubletap', function() {
      vm.$emit('zoomin', { scaleAmount: 1 });
    });

    hammertime.on('pinchend', vm.adjustImage);
    hammertime.on('panend', vm.adjustImage);

    hammertime.on('pan', function(evt) {
      const newX = vm.currentX + evt.deltaX;
      const newY = vm.currentY + evt.deltaY;
      vm.x = newX;
      vm.y = newY;

      if (evt.isFinal) {
        vm.currentX = newX;
        vm.currentY = newY;
      }
    });
  },

  beforeDestroy() {
    this.$refs.imageRef.removeEventListener('load', this.imageLoadListener);
    this.$refs.imageRef.removeEventListener('transitionend', this.transitionEndListener);
    this.$refs.imageRef.removeEventListener('wheel', this.wheelListener);
    this.$refs.imageRef.removeEventListener('mousedown', this.mouseDownListener);
    this.$refs.imageRef.removeEventListener('mouseup', this.mouseUpListener);
  },
};
</script>

<style lang="sass" scoped>
.image-view
  height: 100%
  width: 100%
  display: flex
  align-items: center
  justify-content: center
  overflow: hidden
  position: relative

  &__loader
    color: white
    font-size: 1.5rem

  &--state-moving
    cursor: move

  &__image
    transition: transform .2s
    max-height: 100%
    max-width: 100%
    position: relative
    cursor: grab
    touch-action: none
    display: none
    &--loaded
      display: block
</style>
